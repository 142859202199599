
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'EmptyList',
  props: {
    displayPastItems: {
      type: Boolean,
      default: false,
    },
    listType: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const messageEmptyList = computed(() => {
      if (!props.displayPastItems) {
        return `${props.listType} will appear here.`;
      }
      return `Previous ${props.listType} will be displayed here.`;
    });
    return { messageEmptyList };
  },
});
