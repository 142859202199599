import { FlowCompletionActions } from '@caresend/ui-components';

import {
  itineraryFlowCentrifugationCompletionActions,
} from '@/store/modules/itineraryFlow/completionActions/centrifugation';
import { itineraryFlowMailInCompletionActions } from '@/store/modules/itineraryFlow/completionActions/mailIn';
import { itineraryFlowPackingCompletionActions } from '@/store/modules/itineraryFlow/completionActions/packing';

/**
 * If a completion action is defined, the next button will trigger that action
 * rather than being a `<router-link>` to the next step. While the async action
 * is in progress, a loader will show on the next button. If the promise
 * resolves, the user will be navigated to the next step.
 *
 * **To cancel navigation, an error must be thrown, or false must be returned.**
 * Errors thrown within these actions will be shown to the user and prevent
 * navigation to the next step.
 */
export const itineraryFlowCompletionActions: FlowCompletionActions = {
  ...itineraryFlowCentrifugationCompletionActions,
  ...itineraryFlowMailInCompletionActions,
  ...itineraryFlowPackingCompletionActions,
};
