import {
  AuthRoute,
  DbRef,
  EncryptedUser,
  Language,
  MalpracticeInsurance,
  Office,
  OfficeEmailInvitation,
  Role,
  UserStatus,
} from '@caresend/types';
import {
  dbGroupSet,
  dbSet,
  firebaseAuth,
  getRouter,
  getStore,
  getValueOnce,
  toastError,
  toastErrorAndReport,
  toastResolve,
  toastSuccess,
} from '@caresend/ui-components';
import { signInWithCustomToken } from 'firebase/auth';

import { patientURL, webProURL } from '@/data/urls';
import {
  changeUserEmailAddressRequest,
  checkEmailAlreadyInUseRequest,
  createUserWithEmailLinkRequest,
  refreshTokenOnFailureRequest,
  resetPasswordRequest,
  sendFinishSignUpEmailRequest,
} from '@/database/firebase/API';
import { identifyAnonymousUserSegment } from '@/functions/tracking';

export const registerUserWithoutPassword = (
  user: EncryptedUser,
  members?: Array<EncryptedUser>,
  office?: Office,
  invitations?: OfficeEmailInvitation[],
): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const emailLinkURL = user.role === 'patient' ? patientURL : webProURL;
    createUserWithEmailLinkRequest({ user, emailLinkURL, members, office, invitations })
      .then((result: any) => toastResolve(resolve, 'Confirmation email sent!', result))
      .catch((error: any) => reject(toastError(error.message)));
  });

export const sendFinishSignUpLink = (email: string): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    sendFinishSignUpEmailRequest({ email, emailLinkURL: webProURL })
      .then(() => toastResolve(resolve, 'Confirmation email sent!', undefined))
      .catch((error) => reject(toastError(error.message)));
  });

export const changeUserEmailAddress = (email: string, newEmail: string): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    changeUserEmailAddressRequest({ email, newEmail })
      .then(() => toastResolve(resolve, 'Your email has been changed', undefined))
      .catch((error) => reject(toastError(error.message)));
  });

export const signInWithToken = async (
  token: string,
  officeID?: string,
  approveAccount?: boolean,
  retry?: boolean,
): Promise<void> => {
  const router = getRouter();

  try {
    await signInWithCustomToken(firebaseAuth, token);
  } catch {
    if (retry) throw toastError('Something went wrong. Contact support to access your account.');

    const route = router.currentRoute.path.replace('/', '') as AuthRoute;
    const response = await refreshTokenOnFailureRequest({
      approveAccount,
      emailLinkURL: webProURL,
      officeID,
      route,
      token,
    });
    if (!response?.token) throw toastError('This token has expired. Check your email for a new link.');

    const { token: newToken } = response;
    return signInWithToken(newToken, officeID, approveAccount, true);
  }
};

export const verifyStatusIncomplete = async (): Promise<true> => {
  const store = getStore();

  const userID = store.state.auth.user?.id;
  if (userID === undefined) {
    throw new Error('User not authenticated when verifying status.');
  }
  const user = await getValueOnce<EncryptedUser>(`${DbRef.USERS}/${userID}`);
  if (!user) throw toastError('Unknown user');
  const { status } = user;
  if (status === UserStatus.INCOMPLETE) return true;
  throw toastError('Account already registered');
};

export const resetPassword = async (email: string): Promise<boolean> => {
  const isEmailSent = await resetPasswordRequest({ email, emailLinkURL: webProURL });
  if (!isEmailSent) throw toastError('Something went wrong. Contact support to reset your password.');

  toastSuccess('We just sent you an email, please check your inbox to reset your password.');
  return isEmailSent;
};

/** Firebase auth has initialized and the user is not signed in. */
export const handleAuthInitializedWithoutUser = () => identifyAnonymousUserSegment();

export const checkEmailAlreadyInUse = async (
  email: string,
): Promise<{ email: string; isInUse: boolean }> => {
  const { isInUse } = await checkEmailAlreadyInUseRequest({ email });
  return { isInUse, email };
};

export const updateUserStatusOnVerification = (user: EncryptedUser): Promise<void> =>
  new Promise((resolve, reject) => {
    const { role } = user;
    let newStatus: UserStatus | undefined;
    switch (role) {
      case Role.NURSE:
        newStatus = UserStatus.PENDING;
        break;
      case Role.PATIENT:
        newStatus = UserStatus.APPROVED;
        break;
      case Role.PRESCRIBER:
      case Role.ASSISTANT:
        newStatus = UserStatus.REQUIRE_APPROVAL;
        break;
    }
    if (newStatus) {
      dbSet<UserStatus>(`${DbRef.USERS}/${user.id}/status`, newStatus)
        .then(resolve)
        .catch(reject);
    }
  });

export const completeNurseInfo = async (
  userID: string,
  languages: Language[],
  malpracticeInsurance: MalpracticeInsurance,
): Promise<void> => {
  const update = {
    [`${DbRef.USERS}/${userID}/professionalInfo/malpracticeInsurance`]: malpracticeInsurance,
    [`${DbRef.USERS}/${userID}/info/languages`]: languages,
    [`${DbRef.USERS}/${userID}/status`]: UserStatus.PENDING,
  };
  try {
    await dbGroupSet<MalpracticeInsurance | Language[] | UserStatus>(update);
    toastSuccess('Your profile has been updated');
  } catch {
    toastErrorAndReport(
      'An error has occurred while trying to save your profile.',
    );
  }
};
