import { HeadingParams } from '@caresend/ui-components';
import { RouteConfig } from 'vue-router';

import { routeNames } from '@/router/model';
import { itineraryFlowHeadingWithNav } from '@/router/routes/itineraryFlow/helpers';

// Paths below are not full paths - they are based on the parent path:
// /:partnerName?/itinerary/:itineraryID/
export const routesPatientWaypoint: RouteConfig[] = [
  {
    path: 'waypoint/:waypointID/waypoint-action/:waypointActionID/procedure/:procedureID/patient-consent',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_PATIENT_CONSENT,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/procedure/ItineraryFlowPatientConsent.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Patient consent'),
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/waypoint-action/:waypointActionID/procedure/:procedureID/patient-diagnosis',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_PATIENT_DIAGNOSIS,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/procedure/ItineraryFlowPatientDiagnosis/ItineraryFlowPatientDiagnosis.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Instructions from physician'),
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/waypoint-action/:waypointActionID/procedure/:procedureID/fasting-compliance',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_FASTING_COMPLIANCE,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/procedure/ItineraryFlowFastingCompliance.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Fasting compliance'),
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/waypoint-action/:waypointActionID/procedure/:procedureID/sample-label-checklist',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SAMPLE_LABEL_CHECKLIST,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/procedure/ItineraryFlowSampleLabelChecklist.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Fill out labels'),
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/waypoint-action/:waypointActionID/procedure/:procedureID/required-patient-action',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_REQUIRED_PATIENT_ACTION,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/procedure/ItineraryFlowRequiredPatientAction.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Required patient action'),
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/waypoint-action/:waypointActionID/procedure/:procedureID/collection-instructions',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_COLLECTION_INSTRUCTIONS,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/procedure/ItineraryFlowCollectionInstructions.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Collection instructions'),
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/waypoint-action/:waypointActionID/procedure/:procedureID/transfer-instructions',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_TRANSFER_INSTRUCTIONS,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/procedure/ItineraryFlowTransferInstructions.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Transfer instructions'),
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/waypoint-action/:waypointActionID/procedure/:procedureID/charting-samples',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_CHARTING_SAMPLES,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/procedure/ItineraryFlowChartingSamples/ItineraryFlowChartingSamples.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Chart'),
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/waypoint-action/:waypointActionID/procedure'
      + '/:procedureID/charting-samples/:sampleID/scan-sample',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SAMPLE_CHARTING_SCAN_SAMPLE,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/procedure/ItineraryFlowChartingScanSample.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Scan'),
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/waypoint-action/:waypointActionID/procedure'
      + '/:procedureID/charting-samples/:sampleID/complete-charting',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SAMPLE_COMPLETE_CHARTING,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/procedure/ItineraryFlowCompleteCharting.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Complete charting'),
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/waypoint-action/:waypointActionID/procedure'
      + '/:procedureID/charting-samples/:sampleID/put-amber-bag',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SAMPLE_CHARTING_PUT_AMBER_BAG,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/procedure/ItineraryFlowChartingPutAmberBag.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Pack amber bag'),
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/waypoint-action/:waypointActionID/procedure'
      + '/:procedureID/charting-samples/:sampleID/scan-amber-bag',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SAMPLE_CHARTING_SCAN_AMBER_BAG,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/procedure/ItineraryFlowChartingScanAmberBag.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Scan amber bag'),
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/waypoint-action/:waypointActionID/procedure'
      + '/:procedureID/charting-samples/:sampleID/put-insulated-bag',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SAMPLE_CHARTING_PUT_INSULATED_BAG,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/procedure/ItineraryFlowChartingPutInsulatedBag.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Pack insulated bag'),
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/waypoint-action/:waypointActionID/procedure'
      + '/:procedureID/charting-samples/:sampleID/scan-insulated-bag',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SAMPLE_CHARTING_SCAN_INSULATED_BAG,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/procedure/ItineraryFlowChartingScanInsulatedBag.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Scan insulated bag'),
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/waypoint-action/:waypointActionID/procedure'
     + '/:procedureID/charting-samples/:sampleID/put-specimen-bag',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SAMPLE_CHARTING_PUT_SPECIMEN_BAG,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/procedure/ItineraryFlowChartingPutSpecimenBag.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Pack specimen bag'),
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/waypoint-action/:waypointActionID/procedure'
      + '/:procedureID/charting-samples/seal-specimen-bag',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_CHARTING_SEAL_SPECIMEN_BAG,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/procedure/ItineraryFlowChartingSealSpecimenBag.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Seal specimen bag'),
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/waypoint-action/:waypointActionID/procedure'
      + '/:procedureID/charting-samples/scan-specimen-bag',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_CHARTING_SCAN_SPECIMEN_BAG,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/procedure/ItineraryFlowChartingScanSpecimenBag.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Scan specimen bag'),
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/waypoint-action/:waypointActionID/procedure/:procedureID/task',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_CHARTING,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/procedure/ItineraryFlowCharting/ItineraryFlowCharting.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Charting details'),
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/waypoint-action/:waypointActionID/patient-id',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PATIENT_PATIENT_ID,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/patient/ItineraryFlowPatientID/ItineraryFlowPatientID.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Check patient’s identity'),
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/go-to-patient',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_GO_TO_PATIENT,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/ItineraryFlowGoToPatient/ItineraryFlowGoToPatient.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      noPadding: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Drive to patient visit'),
    },
  },
  {
    path: 'waypoint/:waypointID/action-blocked',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_ACTION_BLOCKED,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@itineraryFlow/steps/shared/ItineraryFlowActionBlocked.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      noPadding: true,
      profileCompletedRequired: true,
    },
  },
  {
    path: 'waypoint/:waypointID/procedures-supply',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURES_SUPPLY,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/ItineraryFlowProceduresSupply/ItineraryFlowProceduresSupply.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Prepare for the visit'),
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/waypoint-action/:waypointActionID/unlock-supplies',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PATIENT_UNLOCK_SUPPLIES,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/ItineraryFlowUnlockSupplies/ItineraryFlowUnlockSupplies.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Unlock supplies for this patient'),
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/waypoint-action/:waypointActionID/procedure/:procedureID/scan-procedure-supply',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SCAN_PROCEDURE_SUPPLY,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/ItineraryFlowScanProcedureSupply/ItineraryFlowScanProcedureSupply.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Scan procedure kit container'),
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/waypoint-action/:waypointActionID/procedure/:procedureID/scan-procedure-supplies',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SCAN_PROCEDURE_SUPPLIES,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/ItineraryFlowScanProcedureSupplies/ItineraryFlowScanProcedureSupplies.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Pack procedure bag'),
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/waypoint-action/:waypointActionID/procedure/:procedureID/click-procedure-supplies',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_CLICK_PROCEDURE_SUPPLIES,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/ItineraryFlowClickProcedureSupplies.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Pack procedure bag'),
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/waypoint-supply',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_WAYPOINT_SUPPLY,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/ItineraryFlowWaypointSupply/ItineraryFlowWaypointSupply.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Prepare for the visit'),
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/scan-waypoint-supply',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_SCAN_WAYPOINT_SUPPLY,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/ItineraryFlowScanWaypointSupply/ItineraryFlowScanWaypointSupply.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Pack your CareSend bag'),
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/nurse-id',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_NURSE_IDENTITY,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/ItineraryFlowNurseIdentity/ItineraryFlowNurseIdentity.vue'
    ),
    meta: {
      authRequired: true,
      heading: itineraryFlowHeadingWithNav('Show your ID to the patient'),
      noGlobalFooter: true,
      profileCompletedRequired: true,
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/patient-list',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PATIENT_LIST,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/ItineraryFlowPatientList/ItineraryFlowPatientList.vue'
    ),
    meta: {
      authRequired: true,
      heading: itineraryFlowHeadingWithNav('Your appointment'),
      noPadding: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/locate-mail-in-box',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_LOCATE_MAIL_IN_BOX,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/ItineraryFlowLocateMailInBox.vue'
    ),
    meta: {
      authRequired: true,
      heading: itineraryFlowHeadingWithNav('Locate mail-in box'),
      noPadding: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/scan-in-mail-in-box',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_SCAN_IN_MAIL_IN_BOX,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/ItineraryFlowScanInMailInBox.vue'
    ),
    meta: {
      authRequired: true,
      heading: itineraryFlowHeadingWithNav('Scan mail-in box'),
      noPadding: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID/centrifugation-reminder',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_CENTRIFUGATION_REMINDER,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/ItineraryFlowCentrifugationReminder.vue'
    ),
    meta: {
      authRequired: true,
      heading: (params: HeadingParams) => {
        const { waypointID } = params.route.params;
        const someProcessingActionOnWaypointIsDisabled = params.store.getters[
          'waypoint/someProcessingActionOnWaypointIsDisabled'
        ](waypointID ?? '');
        return itineraryFlowHeadingWithNav('Centrifugation is required', {
          ...(!someProcessingActionOnWaypointIsDisabled ? { backLocation: undefined } : {}),
        })(params);
      },
      noGlobalFooter: true,
      profileCompletedRequired: true,
      whiteBackground: true,
    },
  },
  {
    path: 'waypoint/:waypointID',
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_DETAILS,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@patientWaypoint/ItineraryFlowPatientWaypointDetails/ItineraryFlowPatientWaypointDetails.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      noPadding: true,
      profileCompletedRequired: true,
    },
  },
];
