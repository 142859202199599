import { RouteConfig } from 'vue-router';

import { routeNames } from '@/router/model';
import { itineraryFlowHeadingWithNav } from '@/router/routes/itineraryFlow/helpers';

// Paths below are not full paths - they are based on the parent path:
// /:partnerName?/itinerary/:itineraryID/
export const routesUnpackedMailInAction: RouteConfig[] = [
  {
    path: 'packing/:waypointActionID/scan-mail-in-box',
    name: routeNames.ITINERARY_FLOW_UNPACKED_MAIL_IN_ACTION_SCAN_MAIL_IN_BOX,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@itineraryFlow/steps/unpackedMailInAction/ItineraryFlowScanMailInBox.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Scan mail-in box'),
      whiteBackground: true,
    },
  },
  {
    path: 'packing/:waypointActionID/follow-instructions',
    name: routeNames.ITINERARY_FLOW_UNPACKED_MAIL_IN_ACTION_FOLLOW_INSTRUCTIONS,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@itineraryFlow/steps/unpackedMailInAction/ItineraryFlowFollowInstructions.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Prepare the box'),
      whiteBackground: true,
    },
  },
  {
    path: 'packing/:waypointActionID/pack-specimen-bags',
    name: routeNames.ITINERARY_FLOW_UNPACKED_MAIL_IN_ACTION_PACK_SPECIMEN_BAGS,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@itineraryFlow/steps/unpackedMailInAction/ItineraryFlowUnpackedMailInActionPackSpecimenBags.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Pack specimen bags'),
      whiteBackground: true,
    },
  },
];
