import { RouteConfig } from 'vue-router';

import { routeNames } from '@/router/model';
import { itineraryFlowHeadingWithNav } from '@/router/routes/itineraryFlow/helpers';
import StopDetailsSecondaryCopy from '@itineraryFlow/steps/shared/components/StopDetailsSecondaryCopy.vue';

// Paths below are not full paths - they are based on the parent path:
// /:partnerName?/itinerary/:itineraryID/
export const routesPackedMailInWaypoint: RouteConfig[] = [
  {
    path: 'mail-in/:waypointID/stop',
    name: routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_STOP_DETAILS,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@itineraryFlow/steps/shared/ItineraryFlowStopDetails.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Stop details', {
        confirmText: 'Drive',
        secondaryComponent: StopDetailsSecondaryCopy,
      }),
      whiteBackground: true,
    },
  },
  {
    path: 'mail-in/:waypointID/drive',
    name: routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_DRIVE_TO_LOCATION,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@itineraryFlow/steps/shared/ItineraryFlowDriveToLocation.vue'
    ),
    meta: {
      authRequired: true,
      noPadding: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      heading: (headingParams) => itineraryFlowHeadingWithNav('Drive to location', {
        confirmText: 'I’ve arrived',
      })(headingParams),
      whiteBackground: true,
    },
  },
  {
    path: 'mail-in/:waypointID/action-blocked',
    name: routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_ACTION_BLOCKED,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@itineraryFlow/steps/shared/ItineraryFlowActionBlocked.vue'
    ),
    meta: {
      authRequired: true,
      noGlobalFooter: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Action blocked'),
      whiteBackground: true,
    },
  },
  {
    path: 'mail-in/:waypointID/scan',
    name: routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SCAN_ITEMS,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@itineraryFlow/steps/packedMailInWaypoint/ItineraryFlowMailInScanItems.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Scan the package(s)'),
      whiteBackground: true,
    },
  },
  {
    path: 'mail-in/:waypointID/shipments',
    name: routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENTS,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@itineraryFlow/steps/packedMailInWaypoint/ItineraryFlowMailInShipments.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Drop-offs for this location', {
        confirmText: 'Finalize drop-off',
      }),
      whiteBackground: true,
    },
  },
  {
    path: 'mail-in/:waypointID/shipment/:shipmentID/scan-box',
    name: routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENT_SCAN_BOX,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@itineraryFlow/steps/packedMailInWaypoint/shipment/ItineraryFlowMailInShipmentScanBox.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Scan the barcode'),
      whiteBackground: true,
    },
  },
  {
    path: 'mail-in/:waypointID/shipment/:shipmentID/print-label',
    name: routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENT_PRINT_LABEL,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@itineraryFlow/steps/packedMailInWaypoint/shipment/ItineraryFlowMailInShipmentPrintLabel.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Print and Ship'),
      whiteBackground: true,
    },
  },
  {
    path: 'mail-in/:waypointID/shipment/:shipmentID/scan-label',
    name: routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENT_SCAN_LABEL,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@itineraryFlow/steps/packedMailInWaypoint/shipment/ItineraryFlowMailInShipmentScanLabel.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Scan the shipping label'),
      whiteBackground: true,
    },
  },
  {
    path: 'mail-in/:waypointID/shipment/:shipmentID/take-photo',
    name: routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENT_TAKE_PHOTO,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@itineraryFlow/steps/packedMailInWaypoint/shipment/ItineraryFlowMailInShipmentTakePhoto.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Take a photo'),
      whiteBackground: true,
    },
  },
  {
    path: 'mail-in/:waypointID/shipment/:shipmentID/drop-off',
    name: routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENT_DROP_OFF,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@itineraryFlow/steps/packedMailInWaypoint/shipment/ItineraryFlowMailInShipmentDropOff.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Drop off package'),
      whiteBackground: true,
    },
  },
];
