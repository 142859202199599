import { computed } from 'vue';
import { RouteConfig } from 'vue-router';

import { routeNames } from '@/router/model';

const routesWaypoint: RouteConfig[] = [
  {
    path: '/:partnerName?/itinerary/:itineraryID/waypoint/:waypointID/'
      + 'procedure/:procedureID/accepted-samples',
    name: routeNames.ACCEPTED_SAMPLES,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@/components/dropOff/AcceptedSamples/AcceptedSamples.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: () => computed(() => ({
        title: 'Accepted Samples',
        backLocation: {
          name: routeNames.DROP_OFF_DETAILS,
        },
      })),
      whiteBackground: true,
    },
  },
  {
    path: '/:partnerName?/itinerary/:itineraryID/waypoint/:waypointID/'
      + 'procedure/:procedureID/drop-off-details',
    name: routeNames.DROP_OFF_DETAILS,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@/components/dropOff/DropOffDetails/DropOffDetails.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: ({ store, route }) => computed(() => {
        const { procedureID } = route.params;
        const procedure = store.getters['procedures/getProcedureByID'](procedureID);
        const title = procedure?.orderID ? 'Present digital requisition form' : 'Show the screen to the lab tech';
        return {
          title,
          backLocation: {
            name: routeNames.SAMPLES_DROP_OFF,
          },
        };
      }),
      whiteBackground: true,
    },
  },
  {
    path: '/:partnerName?/itinerary/:itineraryID/waypoint/:waypointID/go-to-pick-drop',
    name: routeNames.GO_TO_PLACE_WAYPOINT,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@/components/itinerary/GoToPlaceWaypoint/GoToPlaceWaypoint.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: () => computed(() => ({
        title: 'Drive to location',
        backLocation: {
          name: routeNames.PLACE_DETAILS,
        },
      })),
    },
  },
  {
    path: '/:partnerName?/itinerary/:itineraryID/waypoint/:waypointID/place-details',
    name: routeNames.PLACE_DETAILS,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@/components/booking/PlaceDetails/PlaceDetails.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
    },
  },
  // {
  //   path: '/:partnerName?/itinerary/:itineraryID/waypoint/:waypointID/pick-drop',
  //   name: routeNames.PLACE_WAYPOINT,
  //   props: true,
  //   component: () => import(
  //     /* webpackChunkName: 'chunk-itinerary' */
  //     '@/views/nurse/placeVisit/PlaceWaypoint/PlaceWaypoint.vue'
  //   ),
  //   meta: {
  //     authRequired: true,
  //     profileCompletedRequired: true,
  //   },
  // },
  {
    path: '/:partnerName?/itinerary/:itineraryID/waypoint/:waypointID/'
    + 'procedure/:procedureID/scan-rejected-samples',
    name: routeNames.SCAN_REJECTED_SAMPLES,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@/components/dropOff/ScanRejectedSamples/ScanRejectedSamples.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: ({ prevRoute }) => computed(() => ({
        title: 'Scan the rejected sample',
        backLocation: {
          name: prevRoute?.name ?? routeNames.SCAN_REJECTED_SAMPLES_START,
        },
      })),
    },
  },
  {
    path: '/:partnerName?/itinerary/:itineraryID/waypoint/:waypointID/'
      + 'procedure/:procedureID/scan-rejected-samples-start',
    name: routeNames.SCAN_REJECTED_SAMPLES_START,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@/components/dropOff/ScanRejectedSamplesStart/ScanRejectedSamplesStart.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: () => computed(() => ({
        title: 'Scan rejected samples',
        backLocation: {
          name: routeNames.ACCEPTED_SAMPLES,
        },
      })),
    },
  },
  {
    path: '/:partnerName?/itinerary/:itineraryID/waypoint/:waypointID/'
      + 'procedure/:procedureID/drop-off-scan-specimen-bag',
    name: routeNames.SCAN_SPECIMEN_BAG,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@/components/dropOff/DropOffScanSpecimenBag/DropOffScanSpecimenBag.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: ({ prevRoute }) => computed(() => ({
        title: 'Scan',
        backLocation: {
          name: prevRoute?.name ?? routeNames.SAMPLES_DROP_OFF,
        },
      })),
      whiteBackground: true,
    },
  },
  {
    path: '/:partnerName?/itinerary/:itineraryID/waypoint/:waypointID/samples-drop-off',
    name: routeNames.SAMPLES_DROP_OFF,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@/components/dropOff/SamplesDropOff/SamplesDropOff.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: ({ prevRoute }) => computed(() => ({
        title: 'Drop off the samples',
        backLocation: {
          name: prevRoute?.name ?? routeNames.GO_TO_PLACE_WAYPOINT,
        },
      })),
      whiteBackground: true,
    },
  },
];

export { routesWaypoint };
