import { AuthStatus, LoginStep } from '@caresend/types';
import {
  BasicCard,
  ButtonComponent,
  CardWithHeaderLight,
  Loading,
  TextInput,
  getRoute,
  getRouter,
  getStore,
  homeRoute,
  signIn,
  toastError,
} from '@caresend/ui-components';
import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRefs,
  watch,
} from 'vue';
import { Location } from 'vue-router';

import EmailInput from '@/components/layout/inputs/EmailInput.vue';
import { getUserPasswordCreatedRequest, resetPasswordRequest } from '@/database/firebase/API';
import { resetPassword } from '@/database/firebase/auth';
import { showIntercomMessenger } from '@/database/intercom/methods';

interface State {
  currentStep: LoginStep;
  email: string;
  isEmailValid: boolean;
  isLoading: boolean;
  isLoggingIn: boolean;
  password: string;
  previousStep: LoginStep;
}

export const loginLogic = defineComponent({
  name: 'LoginPage',
  components: {
    BasicCard,
    ButtonComponent,
    CardWithHeaderLight,
    EmailInput,
    Loading,
    TextInput,
  },
  setup() {
    const route = getRoute();
    const router = getRouter();
    const store = getStore();

    // warming up cloud functions to speed up loading time
    getUserPasswordCreatedRequest({ warmUp: true });
    resetPasswordRequest({ warmUp: true });

    const state = reactive<State>({
      currentStep: LoginStep.ENTER_EMAIL,
      email: '',
      isEmailValid: false,
      isLoading: false,
      isLoggingIn: false,
      password: '',
      previousStep: LoginStep.ENTER_EMAIL,
    });

    const containerClassNames = computed(() => {
      const names = [];
      // Hide login form during log in to prevent the login page flashing on
      // the screen after login completes.
      if (state.isLoggingIn) names.push('LoginPage__container--transparent');
      return names;
    });

    const passwordInput = ref<InstanceType<typeof TextInput> | null>(null);

    const setIsEmailValid = (value: boolean) => { state.isEmailValid = value; };

    const login = async () => {
      state.isLoading = true;
      state.isLoggingIn = true;
      try {
        await signIn(state.email, state.password);
      } catch {
        state.isLoggingIn = false;
      }
      state.isLoading = false;
    };

    const goToStep = (nextStep: LoginStep) => {
      state.previousStep = state.currentStep;
      state.currentStep = nextStep;
    };

    const goToPreviousStep = () => {
      goToStep(state.previousStep);
    };

    const sendResetPasswordEmail = async () => {
      state.isLoading = true;
      await resetPassword(state.email);
      state.isLoading = false;
    };

    const getUserPasswordCreated = async () => {
      if (!state.isEmailValid) return;
      state.isLoading = true;
      const { userID, passwordCreated } = await getUserPasswordCreatedRequest({ email: state.email });
      if (!userID) {
        toastError('No user found. Please contact support or try with a different email');
      } else if (passwordCreated) {
        goToStep(LoginStep.ENTER_PASSWORD);
      } else {
        await sendResetPasswordEmail();
        goToStep(LoginStep.RESET_PASSWORD);
      }
      state.isLoading = false;
    };

    const getCurrentLoginStep = (step: LoginStep) => {
      switch (step) {
        case LoginStep.ENTER_EMAIL:
          return {
            textButton: 'Next',
            label: 'Your email',
            value: state.email,
            input: (value: string) => { state.password = value; },
            bottomSentence: 'Don’t have an account?',
            bottomButtonText: 'Contact us',
            clickButton: getUserPasswordCreated,
            buttonDisabled: !state.isEmailValid,
            clickBottomButton: showIntercomMessenger,
          };
        case LoginStep.ENTER_PASSWORD:
          return {
            textButton: 'Login',
            label: 'Your password',
            value: state.password,
            input: (value: string) => { state.password = value; },
            bottomSentence: 'Forgot your password?',
            bottomButtonText: 'Set a new one',
            displayBackButton: true,
            goToPreviousStep: () => goToStep(LoginStep.ENTER_EMAIL),
            clickButton: login,
            clickBottomButton: async () => { await sendResetPasswordEmail(); goToStep(LoginStep.RESET_PASSWORD); },
          };
        case LoginStep.RESET_PASSWORD:
          return {
            textButton: 'Login',
            label: 'We’ve sent you a link to reset your password. Please check your email.',
            bottomSentence: 'Didn’t get the email?',
            bottomButtonText: 'Send again',
            displayBackButton: true,
            goToPreviousStep,
            clickButton: () => { goToStep(LoginStep.ENTER_PASSWORD); },
            clickBottomButton: sendResetPasswordEmail,
          };
      }
    };

    const emailInputClassNames = computed(() => {
      const names = [];
      if (state.currentStep !== LoginStep.ENTER_EMAIL) {
        names.push('LoginPage__hidden-input');
      }
      return names;
    });

    const passwordInputClassNames = computed(() => {
      const names = [];
      if (state.currentStep !== LoginStep.ENTER_PASSWORD) {
        names.push('LoginPage__hidden-input');
      }
      return names;
    });

    const currentStepDetail = computed(() => getCurrentLoginStep(state.currentStep));

    const nextRoute = computed<string | Location>(() => {
      const redirectPath = route.value.query.redirect;
      return typeof redirectPath === 'string' ? redirectPath : homeRoute();
    });
    const authStatus = computed(() => store.state.auth.authStatus);

    // Wait until encryption is initialized and user is bound before redirecting.
    watch(
      [
        () => authStatus.value,
      ],
      ([newAuthStatus]) => {
        if (
          newAuthStatus === AuthStatus.COMPLETE
        ) {
          router.push(nextRoute.value);
        }
      },
    );

    watch(
      () => state.currentStep,
      () => {
        // Auto-focus password input
        if (passwordInput.value) {
          const inputEl = passwordInput.value.$el?.querySelector('input');
          setTimeout(() => {
            inputEl?.focus?.();
          }, 0);
        }
      },
    );

    return {
      ...toRefs(state),
      LoginStep,
      containerClassNames,
      currentStepDetail,
      emailInputClassNames,
      passwordInput,
      passwordInputClassNames,
      setIsEmailValid,
    };
  },
});
