import {
  BaseRouteMeta,
  DevRouteName,
  OrderFlowRouteName,
  SchedulingFlowRouteName,
  VisitsPageRouteName,
} from '@caresend/ui-components';
import VueRouter, { Location, Route } from 'vue-router';

import { ItineraryFlowRouteName, itineraryFlowRouteNames } from '@/router/routes/itineraryFlow/model';
import type { CustomStore } from '@/store/model';

export interface WhiteBackgroundParams {
  store: CustomStore;
}

export interface HeadingParams {
  route: Route;
  prevRoute?: Route;
  store: CustomStore;
  router: VueRouter;
}

export interface CustomRouteMeta extends BaseRouteMeta {
  /**
   * Used for older steps in itinerary flow until global footer is supported
   * everywhere. Currently true on patient visit steps.
   */
  noGlobalFooter?: true;
}

/**
 * Excludes Itinerary Flow routes
 */
export enum ProRouteName {
  // PLACE_WAYPOINT = 'PlaceWaypoint',
  ACCEPTED_SAMPLES = 'AcceptedSamples',
  ACTION_BLOCKED = 'ActionBlocked',
  ADD_COWORKER = 'AddCoworker',
  ADD_PROCEDURE = 'AddProcedure',
  ADD_SUPPLIES = 'AddSupplies',
  ADD_SUPPLIES_TO_PLACE = 'AddSuppliesToPlace',
  ASSISTANT_SIGN_UP = 'AssistantSignUp',
  AVAILABILITY = 'Availability',
  BOOKING_CONFIRMATION = 'BookingConfirmation',
  BOOKING_CONFIRMED = 'BookingConfirmed',
  CHECK_IN = 'CheckIn',
  COMPLETE_INFO = 'CompleteInfo',
  CONNECT_BANK_ACCOUNT = 'ConnectYourBankAccount',
  CURRENT_STEP_BOOKING = 'CurrentStepBooking',
  DONE = 'Done',
  DROP_OFF_DETAILS = 'DropOffDetails',
  EDIT_PROCEDURE = 'EditProcedure',
  FINISH_SIGN_UP = 'FinishSignUp',
  FORGOT_PASSWORD = 'ForgotPassword',
  GO_TO_PLACE_WAYPOINT = 'GoToPlaceWaypoint',
  JOIN_OFFICE = 'JoinOffice',
  LEAVE_FROM = 'LeaveFrom',
  LOCATION_SETTINGS = 'LocationSettings',
  LOGIN = 'Login',
  MANAGE_TEAM = 'ManageTeam',
  MY_PROCEDURES_SETTINGS = 'MyProceduresSettings',
  MY_SUPPLIES = 'MySupplies',
  MY_WALLET = 'MyWallet',
  NOT_FOUND = '404',
  NURSE_SIGN_UP = 'NurseSignUp',
  NURSE_VISITS = 'NurseVisits',
  OFFER = 'Offer',
  OFFER_CONFIRMATION = 'OfferConfirmation',
  OFFER_CONFIRMED = 'OfferConfirmed',
  OFFICE_BOOKING_DETAILS = 'OfficeBookingDetails',
  OFFICE_ORDERS = 'Orders',
  OFFICE_HOME = 'OfficeHome',
  OFFICE_ORDER_DETAILS = 'OfficeOrderDetails',
  OFFICE_PROCEDURE_DETAILS = 'OfficeProcedureDetails',
  OFFICE_SETTINGS = 'OfficeSettings',
  ORDER_KIT = 'OrderKit',
  PICK_AND_PACK = 'PickAndPack',
  PLACE_DETAILS = 'PlaceDetails',
  PRESCRIBER_SIGN_UP = 'PrescriberSignUp',
  PROCEDURE = 'Procedure',
  PROCEDURE_LIST = 'ProcedureList',
  PROFILE = 'Profile',
  REPORTS = 'Reports',
  REQUEST_FILLED = 'RequestFilled',
  RESET_PASSWORD = 'ResetPassword',
  SAMPLES_DROP_OFF = 'SamplesDropOff',
  SAMPLE_LABEL_CHECKLIST = 'SampleLabelChecklist',
  SCAN_REJECTED_SAMPLES = 'ScanRejectedSamples',
  SCAN_REJECTED_SAMPLES_START = 'ScanRejectedSamplesStart',
  SCAN_SHIFT_BAG = 'ScanShiftBag',
  SCAN_SHIFT_SUPPLIES = 'ScanShiftSupplies',
  SCAN_SPECIMEN_BAG = 'ScanSpecimenBag',
  SERVICE_REGION_SETTINGS = 'ServiceRegionSettings',
  SHIFT = 'Shift',
  SHIFT_AREA_CHECK = 'ShiftAreaCheck',
  SHIFT_OFFER = 'ShiftOffer',
  SHIFT_PREFERENCES = 'ShiftPreferences',
  SHIPPING = 'Shipping',
  SIGN_UP = 'SignUp',
  SUPPLIES = 'Supplies',
  SUPPLY_SHIPMENT_SCAN_SUPPLY_BOX = 'SupplyShipmentScanSupplyBox',
  SUPPLY_TRANSFER_CLOSE_PROCEDURE_KIT = 'SupplyTransferCloseProcedureKit',
  SUPPLY_TRANSFER_DONE_PACKING = 'SupplyTransferDonePacking',
  SUPPLY_TRANSFER_PRINT_INSTRUCTION_SHEET = 'SupplyTransferPrintInstructionSheet',
  SUPPLY_TRANSFER_PRINT_REQUISITION_FORM = 'SupplyTransferPrintRequsitionForm',
  SUPPLY_TRANSFER_PRINT_SHIPPING_LABEL = 'SupplyTransferPrintShippingLabel',
  SUPPLY_TRANSFER_PROCEDURES = 'SupplyTransferProcedures',
  SUPPLY_TRANSFER_SCAN_INSTRUCTION_SHEET = 'SupplyTransferScanInstructionSheet',
  SUPPLY_TRANSFER_SCAN_KITS_TO_SUPPLY_BOX = 'SupplyTransferScanKitsToSupplyBox',
  SUPPLY_TRANSFER_SCAN_PROCEDURE_KIT = 'SupplyTransferScanProcedureKit',
  SUPPLY_TRANSFER_SCAN_PROCEDURE_SUPPLIES = 'SupplyTransferScanProcedureSupplies',
  SUPPLY_TRANSFER_SCAN_REQUISITION_FORM = 'SupplyTransferScanRequisitionForm',
  SUPPLY_TRANSFER_SCAN_SHIPPING_LABEL = 'SupplyTransferScanShippingLabel',
  SUPPLY_TRANSFER_SCAN_SUPPLY_BOX = 'SupplyTransferScanSupplyBox',
  SUPPLY_TRANSFER_SUPPLY_BOX_PHOTO = 'SupplyTransferSupplyBoxPhoto',
  USER_SETTINGS = 'UserSettings',
  VERIFY_EMAIL = 'VerifyEmail',
  WITHDRAW_WALLET_BALANCE = 'Withdraw',
  WORK_AREA_SETTINGS = 'WorkAreaSettings',
  WORK_SCHEDULE = 'WorkSchedule',
  WORK_SETTINGS = 'WorkSettings',
}

export type RouteName = ProRouteName
| DevRouteName
| OrderFlowRouteName
| SchedulingFlowRouteName
| ItineraryFlowRouteName
| VisitsPageRouteName;

export const routeNames = {
  ...ProRouteName,
  ...DevRouteName,
  ...OrderFlowRouteName,
  ...SchedulingFlowRouteName,
  ...itineraryFlowRouteNames,
  ...VisitsPageRouteName,
};

export type LocationData = Omit<Location, 'name' | 'path'>

export interface RouteData {
  /**
   * We can avoid some runtime errors being thrown with our custom `push()` and
   * `replace()` error handling, but on initial load, those error handlers are not
   * used, and some internal errors trigger both the custom handlers and
   * `router.onError`. Use this to prevent handling errors more than once.
   */
  errorsHandled: boolean;
  route: Route;
  prevRoute?: Route;
}
