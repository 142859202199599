import { AuthStatus, OfficeInvitationStatus, Role } from '@caresend/types';
import {
  augmentRouter,
  buildMaybePartnerRouteWithCurrentRoute,
  firebaseAuth,
  getRoutesChartingFlow,
  getRoutesDev,
  getRoutesOrderFlow,
  getRoutesSchedulingFlow,
  getRoutesVisitsPage,
  getStore,
  isProdDeployment,
} from '@caresend/ui-components';
import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router';

import { PartnerName } from '@/data/partner';
import { signInWithToken } from '@/database/firebase/auth';
import { encodeFirebaseRef } from '@/database/firebase/methods';
import { trackPageViewSegment } from '@/functions/tracking';
import { routeNames } from '@/router/model';
import { routesItinerary } from '@/router/routes/itinerary';
import { routesLogin } from '@/router/routes/login';
import { routesOffice } from '@/router/routes/office';
import { routesPacker } from '@/router/routes/packer';
import { routesProfile } from '@/router/routes/profile';
import { routesShift } from '@/router/routes/shift';
import { routesSignUp } from '@/router/routes/signup';
import { routesMySupplies } from '@/router/routes/supplies';
import { routesUserSettings } from '@/router/routes/userSettings';
import { routesWallet } from '@/router/routes/wallet';
import { routesWaypoint } from '@/router/routes/waypoint';
import { routesWorkSettings } from '@/router/routes/workSettings';
import type { RootState } from '@/store/model';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  ...routesLogin,
  ...routesSignUp,
  ...routesProfile,
  {
    path: '/:partnerName?/my-wallet',
    component: () => import(
      /* webpackChunkName: 'chunk-profile' */
      '@/components/Wallet/MyWallet.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
    },
    children: [...routesWallet],
  },
  ...getRoutesChartingFlow<RootState>(),
  ...getRoutesDev<RootState>(),
  ...getRoutesOrderFlow<RootState>(),
  ...getRoutesSchedulingFlow<RootState>(),
  ...getRoutesVisitsPage<RootState>(),
  ...routesItinerary,
  ...routesMySupplies,
  ...routesPacker,
  ...routesShift,
  ...routesWaypoint,
  ...routesWorkSettings,
  // Must be placed as low as possible to prevent parts of url such as /login
  // being treated as partnerName.
  {
    path: '/:partnerName?',
    component: () => import(
      /* webpackChunkName: 'chunk-main' */
      /* webpackMode: 'eager' */
      '@/views/Home.vue'
    ),
    // Note: Additional meta is set in `routesOffice` that affects all roles.
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
    },
    children: [...routesOffice],
  },
  ...routesUserSettings,
  {
    name: routeNames.NOT_FOUND,
    path: '*',
    component: () => import(
      /* webpackChunkName: 'chunk-main' */
      /* webpackMode: 'eager' */
      '@/components/layout/error/PageNotFound.vue'
    ),
  },
];

const setLoading = (loading: boolean) => (
  _to: Route,
  from: Route,
  next?: NavigationGuardNext<Vue>,
) => {
  getStore().dispatch('app/updateRouteLoading', loading);
  // Only display loading screen on route change if it's not instant. Typically
  // delays would be caused if js is being downloaded for a code split route.
  if (loading) {
    // Do not delay when user is logging in, as this will cause a 500 ms
    // break between the auth spinner and the route spinner.
    const delay = from.name === routeNames.LOGIN ? 0 : 500;
    setTimeout(() => {
      getStore().dispatch('app/showRouteLoading');
    }, delay);
  }
  next?.();
};

const allowRoute = (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
  const { authRequired, profileCompletedRequired, featureFlag, devOnly } = to.meta ?? {};
  const user = getStore().getters['auth/getUser'];
  const { authStatus } = getStore().state.auth;

  if (
    authRequired
    && (
      !user
      || user.role === Role.PATIENT
      || authStatus !== AuthStatus.COMPLETE
    )
  ) {
    return next(
      buildMaybePartnerRouteWithCurrentRoute(
        { name: routeNames.LOGIN, query: { redirect: to.fullPath } }, to,
      ),
    );
  }

  /**
   * If this user has not been approved and they have clicked on a
   * notification link then they should be redirected to the join
   * office page first to accept their invite before being
   * redirected to the intended page
   */
  const { office } = getStore().state.office;
  if (
    (user?.role === Role.ASSISTANT
    || user?.role === Role.PRESCRIBER)
    && to.name !== routeNames.JOIN_OFFICE
    && office
  ) {
    const email = user?.info.email;
    const encodedEmail = encodeFirebaseRef(email ?? '');
    const invitationStatus = office.invitations[encodedEmail]?.status;
    if (invitationStatus !== OfficeInvitationStatus.ACCEPTED) {
      return next(
        buildMaybePartnerRouteWithCurrentRoute(
          {
            name: routeNames.JOIN_OFFICE,
            query: {
              redirect: to.fullPath,
              approve_account: 'true',
            },
          },
          to,
        ),
      );
    }
  }

  if (profileCompletedRequired && user) {
    const { role, info, professionalInfo } = user;
    if (role === Role.NURSE && (!info?.languages || !professionalInfo?.malpracticeInsurance)) {
      return next(
        buildMaybePartnerRouteWithCurrentRoute(
          { name: routeNames.COMPLETE_INFO, query: { redirect: to.fullPath } },
          to,
        ),
      );
    }
  }

  if (featureFlag) {
    const flag = getStore().getters['variables/getFeatureFlagByName'](featureFlag);
    if (!flag) {
      return next(
        buildMaybePartnerRouteWithCurrentRoute({ name: routeNames.NOT_FOUND }, to),
      );
    }
  }

  if (devOnly && isProdDeployment) {
    return next(
      buildMaybePartnerRouteWithCurrentRoute({ name: routeNames.NOT_FOUND }, to),
    );
  }

  return next();
};

/**
 * Redirects to 404 if a partner route is invalid.
 *
 * i.e., `/remedy` → `Home`, `/remmedy` → `404`.
 */
export const checkPartnerRoute = (
  to: Route,
  _from: Route,
  next: NavigationGuardNext<Vue>,
) => {
  // TODO: Use similar logic as patient app for dynamic partner names if
  // partner/whitelabeling support is reintroduced.
  const isPartnerRoute = !!to.params.partnerName;

  if (isPartnerRoute) {
    const { partnerName } = to.params;

    if (
      !partnerName
      || !Object.values<string>(PartnerName).includes(partnerName)
    ) {
      return next({ name: routeNames.NOT_FOUND });
    }
  }

  return next();
};

const authWithToken = async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
  const { token } = to.query;
  const { currentUser } = firebaseAuth;
  if (token && typeof token === 'string' && !currentUser) {
    try {
      await firebaseAuth.signOut();
      await signInWithToken(token);
      // wait for the user to be added to the store
      await new Promise<void>((resolve) => {
        const unwatch = getStore().watch(
          () => getStore().getters['auth/getUser'],
          (user, oldUser) => {
            if (user !== oldUser) {
              // Resolve the promise once the user is added
              resolve();
              // Stop watching for further changes
              unwatch();
            }
          },
        );
      });
    } catch (error) {
      console.error('Error signing in with token:', error);
    }
  }
  next();
};

/**
 * `initRouter` must not be called until feature flags are loaded.
 *
 * `augmentRouter` applies shared router logic that is not app-specific. It must
 * be called prior to using `getRouter`, `getRoute`, and `getPrevRoute`
 * functions.
 */
export const initRouter = () => {
  /**
   * Rather than exporting the router, use `getRouter()` to access the instance.
   */
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
  });

  router.beforeEach(setLoading(true));
  router.beforeEach(authWithToken);
  router.beforeEach(checkPartnerRoute);
  router.beforeEach(allowRoute);
  router.afterEach(setLoading(false));
  router.afterEach(trackPageViewSegment);

  augmentRouter(router, {
    allowRoute,
  });
};
