/* eslint sort-keys: "error" */
import {
  ByID,
  SampleProcessingAction,
  SupplyInstructionOptions,
  Waypoint,
  WaypointAction,
  WaypointActionStatusName,
  WaypointStatusName,
  WaypointType,
} from '@caresend/types';
import {
  AnyGetters,
  ExtendedCustomModule,
  WaypointModule,
  WaypointState,
  initwaypointModule,
  toastErrorAndReport,
} from '@caresend/ui-components';
import { checkStatus, checkStatuses, nullishFilter } from '@caresend/utils';
import { CurrentLocation } from 'vue-router';

import { packingFlowRoute } from '@/router/locations';
import { getSampleProcessingActionRoute } from '@/router/routes/itineraryFlow/helpers';

type S = WaypointState;

const extraWaypointGetters = {
  /** For patient waypoint actions only */
  'waypoint/getIncompleteSampleProcessingActions': (
    state: S,
    getters: AnyGetters,
  ) => (
    waypointID: string,
  ): SampleProcessingAction[] => {
    try {
      const waypoint: Waypoint | undefined = getters['waypoint/getWaypointByID']?.(waypointID);
      if (!waypoint) throw Error(`Waypoint ${waypointID} not found`);
      if (waypoint.type !== WaypointType.PATIENT) return [];

      const incompleteProcessingActions = Object.keys(
        waypoint.processingActions ?? {},
      ).map((id) => {
        const pa = state.sampleProcessingActions[id];
        /** TODO: add a status for disabled processing actions */
        const isDisabled = !getters[
          'waypoint/processingActionIsActive'
        ]?.(id);
        if (isDisabled) return;
        const isDone = checkStatuses(pa, [
          WaypointActionStatusName.CANCELED,
          WaypointActionStatusName.PACKED,
          WaypointActionStatusName.COMPLETE,
        ]);
        if (!isDone) return pa;
      }).filter(nullishFilter);
      return incompleteProcessingActions;
    } catch (error) {
      toastErrorAndReport(error);
      return [];
    }
  },

  'waypoint/getRouteToIncompleteMailInPackingOnWaypoint': (state: S, getters: AnyGetters) => (
    waypointID: string,
  ): CurrentLocation | undefined => {
    const waypoint: Waypoint | undefined = getters['waypoint/getWaypointByID']?.(waypointID);

    if (waypoint?.type === WaypointType.PICKDROP) {
      const mailInActionThatIsReadyToPack: ByID<WaypointAction> | undefined = getters[
        'waypoint/mailInWaypointActionsThatAreReadyToPack'
      ]?.(waypointID) ?? {};
      const wpa = Object.values(mailInActionThatIsReadyToPack ?? {})[0];
      if (!wpa) return;
      return packingFlowRoute({
        params: { waypointActionID: wpa.id },
      });
    }
  },

  'waypoint/getRouteToNextCentrifugeActionOnWaypoint': (state: S, getters: AnyGetters) => (
    waypointID: string,
  ): CurrentLocation | undefined => {
    const waypoint: Waypoint | undefined = getters['waypoint/getWaypointByID']?.(waypointID);

    const isComplete = checkStatus(waypoint, WaypointStatusName.COMPLETE);
    if (isComplete) return;

    const incompleteSampleProcessingActions = extraWaypointGetters[
      'waypoint/getIncompleteSampleProcessingActions'
    ](state, getters)(waypointID);
    const incompleteCentrifugeAction = incompleteSampleProcessingActions.filter(
      (action) => action.supplyInstruction === SupplyInstructionOptions.CENTRIFUGE,
    )[0];
    if (!incompleteCentrifugeAction) return;

    return getSampleProcessingActionRoute(incompleteCentrifugeAction);
  },

  'waypoint/getRouteToNextSampleProcessingActionOnWaypoint': (
    state: S,
    getters: AnyGetters,
  ) => (
    waypointID: string,
  ): CurrentLocation | undefined => {
    const waypoint: Waypoint | undefined = getters['waypoint/getWaypointByID']?.(waypointID);
    if (waypoint?.status.status !== WaypointStatusName.COMPLETE) return;

    const incompleteSampleProcessingAction = extraWaypointGetters[
      'waypoint/getIncompleteSampleProcessingActions'
    ](state, getters)(waypointID)[0];
    if (!incompleteSampleProcessingAction) return;

    return getSampleProcessingActionRoute(incompleteSampleProcessingAction);
  },

  'waypoint/getRouteToReadyActionInItinerary': (state: S, getters: AnyGetters) => (
    itineraryID: string,
  ): CurrentLocation | undefined => {
    const itinerary = state.itineraries[itineraryID];
    if (!itinerary) return;
    const waypointIDs = itinerary.waypoints.map((idObj) => idObj.id);
    return waypointIDs.map((id) =>
      extraWaypointGetters['waypoint/getRouteToReadyActionInWaypoint'](state, getters)(id),
    ).flat().filter(nullishFilter)[0];
  },

  'waypoint/getRouteToReadyActionInWaypoint': (state: S, getters: AnyGetters) => (
    waypointID: string,
  ): CurrentLocation | undefined => {
    const nextSampleActionRoute = extraWaypointGetters[
      'waypoint/getRouteToNextSampleProcessingActionOnWaypoint'
    ](state, getters)(waypointID);

    if (nextSampleActionRoute) return nextSampleActionRoute;

    const incompleteMailInPacking = extraWaypointGetters[
      'waypoint/getRouteToIncompleteMailInPackingOnWaypoint'
    ](state, getters)(waypointID);

    if (incompleteMailInPacking) return incompleteMailInPacking;
  },

  /** For patient waypoint actions only */
  'waypoint/getWaypointNeedsCentrifuge': (state: S, getters: AnyGetters) => (
    waypointID: string,
  ): boolean => {
    const incompleteActions = extraWaypointGetters[
      'waypoint/getIncompleteSampleProcessingActions'
    ](state, getters)(waypointID);

    return incompleteActions.some((action) =>
      action.supplyInstruction === SupplyInstructionOptions.CENTRIFUGE,
    );
  },
};

const waypointModuleExtension = {
  getters: extraWaypointGetters,
};

export const waypointModule: ExtendedCustomModule<
  WaypointModule,
  typeof waypointModuleExtension
> = initwaypointModule(waypointModuleExtension);

export type ExtendedWaypointModule = typeof waypointModule;

export type ExtendedWaypointMutations = ExtendedWaypointModule['mutations'];
export type ExtendedWaypointActions = ExtendedWaypointModule['actions'];
export type ExtendedWaypointGetters = ExtendedWaypointModule['getters'];
