export enum ItineraryFlowRootRouteName {
  ITINERARY_FLOW_ITINERARY = 'ItineraryFlow/Itinerary',
}

/** Patient waypoint (Patient visit steps) */
export enum ItineraryFlowPatientWaypointRouteName {
  ITINERARY_FLOW_PATIENT_WAYPOINT_DETAILS
    = 'ItineraryFlow/PatientWaypoint/Details',
  ITINERARY_FLOW_PATIENT_WAYPOINT_GO_TO_PATIENT
    = 'ItineraryFlow/PatientWaypoint/GoToPatient',
  ITINERARY_FLOW_PATIENT_WAYPOINT_ACTION_BLOCKED
    = 'ItineraryFlow/PatientWaypoint/ActionBlocked',
  ITINERARY_FLOW_PATIENT_WAYPOINT_NURSE_IDENTITY
    = 'ItineraryFlow/PatientWaypoint/NurseIdentity',
  ITINERARY_FLOW_PATIENT_WAYPOINT_PATIENT_LIST
    = 'ItineraryFlow/PatientWaypoint/PatientList',
  ITINERARY_FLOW_PATIENT_WAYPOINT_LOCATE_MAIL_IN_BOX
    = 'ItineraryFlow/PatientWaypoint/LocateMailInBox',
  ITINERARY_FLOW_PATIENT_WAYPOINT_SCAN_IN_MAIL_IN_BOX
    = 'ItineraryFlow/PatientWaypoint/ScanInMailInBox',
  ITINERARY_FLOW_PATIENT_WAYPOINT_CENTRIFUGATION_REMINDER
    = 'ItineraryFlow/PatientWaypoint/CentrifugationReminder',
  ITINERARY_FLOW_PATIENT_WAYPOINT_PATIENT_PATIENT_ID
    = 'ItineraryFlow/PatientWaypoint/Patient/PatientID',
  ITINERARY_FLOW_PATIENT_WAYPOINT_PATIENT_UNLOCK_SUPPLIES
    = 'ItineraryFlow/PatientWaypoint/Patient/UnlockSupplies',
  ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURES_SUPPLY
    = 'ItineraryFlow/PatientWaypoint/ProceduresSupply',
  ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_CLICK_PROCEDURE_SUPPLIES
    = 'ItineraryFlow/PatientWaypoint/Procedure/ClickProcedureSupplies',
  ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_CHARTING
    = 'ItineraryFlow/PatientWaypoint/Procedure/Charting',
  ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_CHARTING_SAMPLES
    = 'ItineraryFlow/PatientWaypoint/Procedure/ChartingSamples',
  ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_CHARTING_SCAN_SPECIMEN_BAG
    = 'ItineraryFlow/PatientWaypoint/Procedure/ChartingScanSpecimenBag',
  ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_CHARTING_SEAL_SPECIMEN_BAG
    = 'ItineraryFlow/PatientWaypoint/Procedure/ChartingSealSpecimenBag',
  ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_COLLECTION_INSTRUCTIONS
    = 'ItineraryFlow/PatientWaypoint/Procedure/CollectionInstructions',
  ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_FASTING_COMPLIANCE
    = 'ItineraryFlow/PatientWaypoint/Procedure/FastingCompliance',
  ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_PATIENT_CONSENT
    = 'ItineraryFlow/PatientWaypoint/Procedure/PatientConsent',
  ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_PATIENT_DIAGNOSIS
    = 'ItineraryFlow/PatientWaypoint/Procedure/PatientDiagnosis',
  ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_REQUIRED_PATIENT_ACTION
    = 'ItineraryFlow/PatientWaypoint/Procedure/RequiredPatientAction',
  ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SAMPLE_CHARTING_PUT_AMBER_BAG
    = 'ItineraryFlow/PatientWaypoint/Procedure/Sample/ChartingPutAmberBag',
  ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SAMPLE_CHARTING_PUT_INSULATED_BAG
    = 'ItineraryFlow/PatientWaypoint/Procedure/Sample/ChartingPutInsulatedBag',
  ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SAMPLE_CHARTING_PUT_SPECIMEN_BAG
    = 'ItineraryFlow/PatientWaypoint/Procedure/Sample/ChartingPutSpecimenBag',
  ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SAMPLE_CHARTING_SCAN_AMBER_BAG
    = 'ItineraryFlow/PatientWaypoint/Procedure/Sample/ChartingScanAmberBag',
  ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SAMPLE_CHARTING_SCAN_INSULATED_BAG
    = 'ItineraryFlow/PatientWaypoint/Procedure/Sample/ChartingScanInsulatedBag',
  ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SAMPLE_CHARTING_SCAN_SAMPLE
    = 'ItineraryFlow/PatientWaypoint/Procedure/Sample/ChartingScanSample',
  ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SAMPLE_COMPLETE_CHARTING
    = 'ItineraryFlow/PatientWaypoint/Procedure/Sample/CompleteCharting',
  ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SAMPLE_LABEL_CHECKLIST
    = 'ItineraryFlow/PatientWaypoint/Procedure/SampleLabelChecklist',
  ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SCAN_PROCEDURE_SUPPLIES
    = 'ItineraryFlow/PatientWaypoint/Procedure/ScanProcedureSupplies',
  ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SCAN_PROCEDURE_SUPPLY
    = 'ItineraryFlow/PatientWaypoint/Procedure/ScanProcedureSupply',
  ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_TRANSFER_INSTRUCTIONS
    = 'ItineraryFlow/PatientWaypoint/Procedure/TransferInstructions',
  ITINERARY_FLOW_PATIENT_WAYPOINT_SCAN_WAYPOINT_SUPPLY
    = 'ItineraryFlow/PatientWaypoint/ScanWaypointSupply',
  ITINERARY_FLOW_PATIENT_WAYPOINT_WAYPOINT_SUPPLY
    = 'ItineraryFlow/PatientWaypoint/WaypointSupply',
}

/** Centrifuge processing action (Centrifugation steps) */
export enum ItineraryFlowCentrifugationRouteName {
  ITINERARY_FLOW_CENTRIFUGATION_SCAN_SPECIMEN_BAGS
    = 'ItineraryFlow/Centrifugation/ScanSpecimenBags',
  ITINERARY_FLOW_CENTRIFUGATION_SCAN_SAMPLES
    = 'ItineraryFlow/Centrifugation/ScanSamples',
  ITINERARY_FLOW_CENTRIFUGATION_INSTRUCTIONS
    = 'ItineraryFlow/Centrifugation/Instructions',
  ITINERARY_FLOW_CENTRIFUGATION_PHOTO_CONFIRMATION
    = 'ItineraryFlow/Centrifugation/PhotoConfirmation',
  ITINERARY_FLOW_CENTRIFUGATION_PACK_SPECIMEN_BAGS
    = 'ItineraryFlow/Centrifugation/PackSpecimenBags',
  ITINERARY_FLOW_CENTRIFUGATION_PROCEDURE_SCAN_SPECIMEN_BAG
    = 'ItineraryFlow/Centrifugation/Procedure/ScanSpecimenBag',
  ITINERARY_FLOW_CENTRIFUGATION_PROCEDURE_SCAN_SAMPLES
    = 'ItineraryFlow/Centrifugation/Procedure/ScanSamples',
}

/**
 * Unpacked mail-in action (Packing steps)
 * - This flow will be visited once for each mail-in action on a waypoint.
 */
export enum ItineraryFlowUnpackedMailInActionRouteName {
  ITINERARY_FLOW_UNPACKED_MAIL_IN_ACTION_SCAN_MAIL_IN_BOX
    = 'ItineraryFlow/UnpackedMailInAction/ScanMailInBox',
  ITINERARY_FLOW_UNPACKED_MAIL_IN_ACTION_FOLLOW_INSTRUCTIONS
    = 'ItineraryFlow/UnpackedMailInAction/FollowInstructions',
  ITINERARY_FLOW_UNPACKED_MAIL_IN_ACTION_PACK_SPECIMEN_BAGS
    = 'ItineraryFlow/UnpackedMailInAction/PackSpecimenBags',
}

/**
 * Packed mail-in waypoint (Mail-in steps)
 * - This flow will be visited once for each waypoint that contains one or
 *   more mail-in actions.
 */
export enum ItineraryFlowPackedMailInWaypointRouteName {
  ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_STOP_DETAILS
    = 'ItineraryFlow/PackedMailInWaypoint/StopDetails',
  ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_DRIVE_TO_LOCATION
    = 'ItineraryFlow/PackedMailInWaypoint/DriveToLocation',
  ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_ACTION_BLOCKED
    = 'ItineraryFlow/PackedMailInWaypoint/ActionBlocked',
  ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SCAN_ITEMS
    = 'ItineraryFlow/PackedMailInWaypoint/ScanItems',
  ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENTS
    = 'ItineraryFlow/PackedMailInWaypoint/Shipments',
  ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENT_SCAN_BOX
    = 'ItineraryFlow/PackedMailInWaypoint/Shipment/ScanBox',
  ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENT_PRINT_LABEL
    = 'ItineraryFlow/PackedMailInWaypoint/Shipment/PrintLabel',
  ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENT_SCAN_LABEL
    = 'ItineraryFlow/PackedMailInWaypoint/Shipment/ScanLabel',
  ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENT_TAKE_PHOTO
    = 'ItineraryFlow/PackedMailInWaypoint/Shipment/TakePhoto',
  ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENT_DROP_OFF
    = 'ItineraryFlow/PackedMailInWaypoint/Shipment/DropOff',
}

export type ItineraryFlowRouteName = ItineraryFlowRootRouteName
| ItineraryFlowPatientWaypointRouteName
| ItineraryFlowCentrifugationRouteName
| ItineraryFlowUnpackedMailInActionRouteName
| ItineraryFlowPackedMailInWaypointRouteName;

export const itineraryFlowRouteNames = {
  ...ItineraryFlowRootRouteName,
  ...ItineraryFlowPatientWaypointRouteName,
  ...ItineraryFlowCentrifugationRouteName,
  ...ItineraryFlowUnpackedMailInActionRouteName,
  ...ItineraryFlowPackedMailInWaypointRouteName,
};
