/* eslint-disable max-len */
import { RouteConfig } from 'vue-router';

import { routeNames } from '@/router/model';
import { itineraryFlowHeadingWithNav } from '@/router/routes/itineraryFlow/helpers';

// Paths below are not full paths - they are based on the parent path:
// /:partnerName?/itinerary/:itineraryID/
export const routesCentrifugationProcessing: RouteConfig[] = [
  {
    path: 'centrifugation/:processingActionID/scan-specimen-bags',
    name: routeNames.ITINERARY_FLOW_CENTRIFUGATION_SCAN_SPECIMEN_BAGS,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@itineraryFlow/steps/centrifugation/scanSpecimenBags/ItineraryFlowCentrifugeScanSpecimenBags.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Scan specimen bags'),
      whiteBackground: true,
    },
  },
  {
    path: 'centrifugation/:processingActionID/scan-samples',
    name: routeNames.ITINERARY_FLOW_CENTRIFUGATION_SCAN_SAMPLES,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@itineraryFlow/steps/centrifugation/scanSamples/ItineraryFlowCentrifugeScanSamples.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Put tubes in centrifuge'),
      whiteBackground: true,
    },
  },
  {
    path: 'centrifugation/:processingActionID/instructions',
    name: routeNames.ITINERARY_FLOW_CENTRIFUGATION_INSTRUCTIONS,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@itineraryFlow/steps/centrifugation/ItineraryFlowCentrifugeInstructions.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Centrifuge tubes'),
      whiteBackground: true,
    },
  },
  {
    path: 'centrifugation/:processingActionID/photo-confirmation',
    name: routeNames.ITINERARY_FLOW_CENTRIFUGATION_PHOTO_CONFIRMATION,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@itineraryFlow/steps/centrifugation/ItineraryFlowCentrifugePhotoConfirmation.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Take a photo'),
      whiteBackground: true,
    },
  },
  {
    path: 'centrifugation/:processingActionID/pack-specimen-bags',
    name: routeNames.ITINERARY_FLOW_CENTRIFUGATION_PACK_SPECIMEN_BAGS,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@itineraryFlow/steps/centrifugation/packSpecimenBags/ItineraryFlowCentrifugePackSpecimenBags.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Pack new specimen bag(s)'),
      whiteBackground: true,
    },
  },
  {
    path: 'centrifugation/:processingActionID/procedure/:procedureID/scan-specimen-bag',
    name: routeNames.ITINERARY_FLOW_CENTRIFUGATION_PROCEDURE_SCAN_SPECIMEN_BAG,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@itineraryFlow/steps/centrifugation/procedure/ItineraryFlowCentrifugeProcedureScanSpecimenBag.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Pack new specimen bag'),
      whiteBackground: true,
    },
  },
  {
    path: 'centrifugation/:processingActionID/procedure/:procedureID/scan-samples',
    name: routeNames.ITINERARY_FLOW_CENTRIFUGATION_PROCEDURE_SCAN_SAMPLES,
    props: true,
    component: () => import(
      /* webpackChunkName: 'chunk-itinerary' */
      '@itineraryFlow/steps/centrifugation/procedure/scanSamples/ItineraryFlowCentrifugeProcedureScanSamples.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: itineraryFlowHeadingWithNav('Put tubes into new bag'),
      whiteBackground: true,
    },
  },
];
